import type { Hono } from 'hono';
import { hc } from 'hono/client'
import type { ValidationTargets } from 'hono';
import type { JsonRequest, JsonResponse } from './http';
import type { UnionToIntersection } from 'hono/utils/types';

export { UnionToIntersection }

export type InferInput<T> = T extends (ctx: infer C) => any
  ? C extends { request: infer R }
    ? R extends JsonRequest<infer J>
      ? J
      : never
    : never
  : never;

export type InferOutput<T> = T extends (ctx: any) => infer R
  ? R extends Promise<JsonResponse<infer J>>
    ? J
    : never
  : never;

export type OpenAPI2Hono<S> = {
  [Path in keyof S]: {
    $post: {
      input: Partial<Omit<ValidationTargets, 'json'>> & {
        json: InferInput<S[Path]>;
        param?: Record<string, string>;
      },
      output: InferOutput<S[Path]>;
      outputFormat: 'json';
      status: 200;
    };
  };
};

export function HonoClient<S>(...params: Parameters<typeof hc>) {
  return hc<Hono<{}, OpenAPI2Hono<S>>>(...params);
}
